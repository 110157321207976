'use strict'

const at = require('array.prototype.at')

/**
 * Polyfills the Array.prototype.at method
 * Required for pdfjs
 */
if (!Array.prototype.at) {
  Array.prototype.at = at
}

module.exports = {}
