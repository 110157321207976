import { Box, Flex, Stack, Text } from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'

import { DocExpiredSvgr, DocNotFoundSvgr, NotFoundSvgr } from './svg'

enum NotFoundType {
  notFound,
  docNotFound,
  docExpired,
}

const NotFoundPageTemplate = ({
  notFoundType,
  mainText,
  subText,
}: {
  notFoundType: NotFoundType
  mainText: string
  subText: string
}) => (
  <>
    <Flex flex={1} flexDir="column" h="100%">
      <Flex
        justify="center"
        align="center"
        flexDir="column"
        flex={1}
        bgGradient={{
          base: 'linear(to-b, brand.primary.500, brand.primary.500 40%, brand.primary.50 0)',
          md: 'linear(to-b, brand.primary.500 50%, brand.primary.50 50%)',
        }}
      >
        <SvgBuilder notFoundType={notFoundType} />
        <Stack
          spacing="1rem"
          color="secondary.500"
          align="center"
          textAlign="center"
        >
          <Text as="h2" textStyle="h2" fontSize="24px">
            {mainText}
          </Text>
          <Text textStyle="body-1">{subText}</Text>
        </Stack>
      </Flex>
      <Box py={{ lg: '3rem' }} px={{ lg: '9.25rem' }} bg="brand.primary.50">
        <AppFooter />
      </Box>
    </Flex>
  </>
)

const SvgBuilder = ({ notFoundType }: { notFoundType: NotFoundType }) => {
  const svgProps = {
    maxW: '100%',
    mb: { base: '1.5rem', md: '4rem' },
    maxH: { base: '220px', md: 'initial' },
  }

  switch (notFoundType) {
    case NotFoundType.notFound:
      return <NotFoundSvgr {...svgProps} />
    case NotFoundType.docExpired:
      return <DocExpiredSvgr {...svgProps} />
    case NotFoundType.docNotFound:
      return <DocNotFoundSvgr {...svgProps} />
  }
}

export const NotFoundErrorPage = (): JSX.Element => (
  <NotFoundPageTemplate
    notFoundType={NotFoundType.notFound}
    mainText="This page could not be found."
    subText="Check that you have the correct link."
  />
)

export const DocExpiredPage = (): JSX.Element => (
  <NotFoundPageTemplate
    notFoundType={NotFoundType.docExpired}
    mainText="This document has expired."
    subText="This document has passed its expiry date and has been automatically deleted."
  />
)

export const DocDeletedPage = (): JSX.Element => (
  <NotFoundPageTemplate
    notFoundType={NotFoundType.docNotFound}
    mainText="This document has been deleted by the agency."
    subText="If you think this is a mistake, please contact the agency that gave you the document link."
  />
)
