export const SIGNEE_GUIDE = 'https://go.gov.sg/signify-guide-signers'
export const SIGNIFY_GUIDE = 'https://go.gov.sg/signify-guide'
export const SIGNIFY_API_GUIDE = 'https://go.gov.sg/signify-api-guide'
export const SIGNIFY_SUPPORT = 'https://go.gov.sg/signify-support'
export const SIGNIFY_PRIVACY = 'https://go.gov.sg/signify-privacy-policy'
export const SIGNIFY_TOS = 'https://go.gov.sg/signify-terms-of-use'

export const SINGPASS_LINK = 'https://app.singpass.gov.sg/'

export const OGP_SITE = 'https://www.open.gov.sg/'

export const DATA_CLASSIFICATIONS = 'https://go.gov.sg/data-classifications'
