import { PropsWithChildren, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { routes } from '~constants/routes'
import { lazyImport } from '~utils/lazyImport'

import {
  DocExpiredPage,
  NotFoundErrorPage,
} from '~pages/NotFoundError/NotFoundPage'

const { AuthRoutes } = lazyImport(() => import('~features/auth'), 'AuthRoutes')
const { DashboardRoutes } = lazyImport(
  () => import('~features/dashboard'),
  'DashboardRoutes',
)
const { DocumentRoutes } = lazyImport(
  () => import('~features/dashboard'),
  'DocumentRoutes',
)
const { HealthRoutes } = lazyImport(
  () => import('~features/health'),
  'HealthRoutes',
)
const { ApiIntegrationRoutes } = lazyImport(
  () => import('~features/apiIntegration'),
  'ApiIntegrationRoutes',
)

const { TaskRoutes } = lazyImport(() => import('~features/task'), 'TaskRoutes')

const router = createBrowserRouter([
  {
    path: routes.index,
    element: <DashboardRoutes />,
  },
  {
    path: routes.login,
    element: <AuthRoutes />,
  },
  {
    path: routes.health,
    element: <HealthRoutes />,
  },
  {
    path: routes.signDocument,
    element: <TaskRoutes />,
  },
  {
    path: routes.document,
    element: <DocumentRoutes />,
  },
  {
    path: routes.apiIntegration,
    element: <ApiIntegrationRoutes />,
  },
  {
    path: '410',
    element: <DocExpiredPage />,
  },
  {
    path: '*',
    element: <NotFoundErrorPage />,
  },
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  )
}
