import { useNavigate } from 'react-router-dom'
import { Box, Flex } from '@chakra-ui/react'
import {
  Button,
  IconButton,
  Link,
  RestrictedFooter,
} from '@opengovsg/design-system-react'

import {
  OGP_SITE,
  SIGNIFY_GUIDE,
  SIGNIFY_PRIVACY,
  SIGNIFY_SUPPORT,
  SIGNIFY_TOS,
} from '~constants/links'

import OgpLogo from '../assets/icons/OgpLogo'
import SignifyBrandLogo from '../assets/icons/SignifyBrandLogo'
import { useIsMobile } from '../hooks/useScreenSize'

export const AppFooter = (): JSX.Element => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return isMobile ? (
    <RestrictedFooter
      appName="Signify"
      appLink="https://signify.gov.sg/"
      footerLinks={[
        {
          label: 'Contact',
          href: 'https://go.gov.sg/signify-support',
        },
        {
          label: 'Guide',
          href: 'https://go.gov.sg/signify-guide',
        },
        {
          label: 'Privacy',
          href: 'https://go.gov.sg/signify-privacy-policy',
        },
        {
          label: 'Terms of Use',
          href: 'https://go.gov.sg/signify-terms-of-use',
        },
        {
          label: 'Report Vulnerability',
          href: 'https://go.gov.sg/signify-support',
        },
      ]}
    />
  ) : (
    <>
      <Flex dir="row" justifyContent="space-between">
        <Flex dir="row" alignItems="center" gap="1.5rem">
          <Button
            w="6.5rem"
            as={IconButton}
            icon={<SignifyBrandLogo />}
            variant="clear"
            aria-label="Signify"
            onClick={() => navigate('/')}
          />
          <Box w="1px" h="2.25rem" bg="base.divider.medium" />
          <Button
            as={IconButton}
            icon={<OgpLogo />}
            variant="clear"
            aria-label="Signify"
            mt="0.25rem"
            onClick={() => window.open(OGP_SITE, '_blank')}
          />
        </Flex>
        <Flex dir="row" alignItems="center" gap="1.5rem">
          <Link
            href={SIGNIFY_SUPPORT}
            target="_blank"
            textDecorationLine="none"
          >
            Contact
          </Link>
          <Link href={SIGNIFY_GUIDE} target="_blank" textDecorationLine="none">
            Guide
          </Link>
          <Link
            href={SIGNIFY_PRIVACY}
            target="_blank"
            textDecorationLine="none"
          >
            Privacy
          </Link>
          <Link href={SIGNIFY_TOS} target="_blank" textDecorationLine="none">
            Terms of Use
          </Link>
          <Link
            href={SIGNIFY_SUPPORT}
            target="_blank"
            textDecorationLine="none"
          >
            Report vulnerability
          </Link>
        </Flex>
      </Flex>
    </>
  )
}
