import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import wretch from 'wretch'

import { UNAUTHORIZED_EVENT } from '~constants/events'

import { STORAGE_LOGGED_IN_KEY } from '~features/auth'

import { ApiError, HttpError } from '../../../shared/src/types/core'

/**
 * Default API client pointing to backend.
 * Automatically catches 403 errors and invalidates authentication state.
 */
export const api = wretch('/api/v1')
  .catcher(403, (err) => {
    window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
    throw err
  })
  .errorType('json')

export const AxiosService = axios.create({
  baseURL: '/api/v1',
  withCredentials: true,
})

/**
 * Axios interceptor to transform AxiosErrors to normal Errors
 */
AxiosService.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      // Remove logged in state from localStorage
      localStorage.removeItem(STORAGE_LOGGED_IN_KEY)
      // Event to let useLocalStorage know that key is being deleted.
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
    }
    const transformedError = transformAxiosError(error)
    throw transformedError
  },
)

/**
 * Converts possible AxiosError objects to normal Error objects
 *
 * @returns HttpError if AxiosError, else original error
 */
export const transformAxiosError = (error: Error): ApiError => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const statusCode = error.response.status
      if (statusCode === StatusCodes.TOO_MANY_REQUESTS) {
        return new HttpError('Please try again later.', statusCode)
      }
      if (typeof error.response.data === 'string') {
        return new HttpError(error.response.data, statusCode)
      }
      if (error.response.data?.message) {
        return new HttpError(error.response.data.message, statusCode)
      }
      if (error.response.statusText) {
        return new HttpError(error.response.statusText, statusCode)
      }
      return new HttpError(`Error: ${statusCode}`, statusCode)
    } else if (error.request) {
      return new Error(
        `There was a problem with your internet connection. Please check your network and try again. ${error.message}`,
      )
    }
  }
  return error
}
