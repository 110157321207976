import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { datadogRum } from '@datadog/browser-rum'

import App from './app'
import * as serviceWorker from './serviceWorker'

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_RUM_APP_ID || '',
  clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'signify',
  env: process.env.REACT_APP_DD_RUM_ENV || '',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.REACT_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [/https:\/\/.*(\.)?signify\.gov\.sg/],
})

datadogRum.startSessionReplayRecording()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
