import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const DocNotFoundSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="457"
    height="262"
    viewBox="0 0 457 262"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M455.815 44.0439H74.5215L28.7119 260.999H410.005L455.815 44.0439Z"
      fill="#F8F9FD"
    />
    <path
      d="M382.293 84.5459L351.769 239.979L403.865 261H410.005L433.165 151.295L382.293 84.5459Z"
      fill="#BABECB"
    />
    <path
      d="M382.293 84.5459L351.769 239.979L403.865 261H410.005L423.779 195.757L382.293 84.5459Z"
      fill="#9AA0B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M455.815 44.0439H74.5215L28.7119 260.999H410.005L455.815 44.0439Z"
      stroke="black"
    />
    <path
      d="M391.982 1H98.4412L63.1744 168.393H356.715L391.982 1Z"
      fill="#8D9DE1"
      stroke="black"
    />
    <g opacity="0.5">
      <path
        d="M351.924 32.5369L120.96 29.9882"
        stroke="#697FD7"
        strokeWidth="3"
      />
      <path
        d="M331.605 67.9538L100.649 65.4052"
        stroke="#697FD7"
        strokeWidth="3"
      />
    </g>
    <path
      d="M171.249 84.546L156.509 57.8066H1L28.7119 261H410.005L382.293 84.546H171.249Z"
      fill="#F8F9FD"
    />
    <path
      d="M231.165 84.546H171.249L156.509 57.8066H1L28.7119 261H410.005L382.293 84.546H230.235"
      stroke="black"
    />
    <path
      d="M170.313 144.86L182.976 131.864L185.521 134.477L172.917 147.487L172.664 147.748L172.917 148.008L185.521 161.018L182.975 163.631L170.312 150.709L170.044 150.435L169.776 150.71L157.183 163.634L154.571 161.016L167.245 148.009L167.5 147.748L167.245 147.486L154.571 134.479L157.182 131.862L169.775 144.859L170.044 145.136L170.313 144.86ZM240.448 144.86L253.111 131.864L255.657 134.477L243.052 147.487L242.799 147.748L243.052 148.008L255.657 161.018L253.11 163.631L240.448 150.709L240.179 150.435L239.911 150.71L227.319 163.634L224.707 161.016L237.38 148.009L237.635 147.748L237.38 147.486L224.707 134.479L227.318 131.862L239.911 144.859L240.179 145.136L240.448 144.86ZM209.871 187.861V187.486H209.496H155.808V183.737H255.878V187.486H247.486H247.111V187.861V196.109C247.111 206.708 238.795 215.229 228.491 215.229C218.186 215.229 209.871 206.708 209.871 196.109V187.861Z"
      fill="#445072"
      stroke="#445072"
      strokeWidth="0.749841"
    />
  </svg>
))
