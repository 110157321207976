import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from '@opengovsg/design-system-react'

import { theme } from '~/theme'

import { AuthProvider } from '~lib/auth'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient()

export const App = (): JSX.Element => (
  // TODO: Remove this once mobile is supported
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    {/* Manually unset portalZIndex, due to known bug that toasts appear below modal
      https://github.com/chakra-ui/chakra-ui/issues/6276
       */}
    <ThemeProvider theme={theme} resetCSS portalZIndex={undefined}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  </QueryClientProvider>
)
